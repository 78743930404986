<template>
  <div class="tabBar">
    <router-link tag="div" active-class="active" to="/extract" class="bar">
      <i class="font_family icon-renwuzhongxin"></i>
      <span>提取文案</span>
    </router-link>
    <router-link tag="div" active-class="active" to="/extension" class="bar">
      <i class="font_family icon-tanqiuhehuoren-wodetuiguang"></i>
      <span>我的推广</span>
    </router-link>
    <router-link tag="div" active-class="active" to="/profit" class="bar">
      <i class="font_family icon-tongji"></i>
      <span>收益数据</span>
    </router-link>
    <router-link tag="div" active-class="active" to="/information" class="bar">
      <i class="font_family icon-yonghuxinxiguanli"></i>
      <span>个人中心</span>
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'tabBar',
  computed: {
    wechat() {
      return this.$store.state.user.wechat
    }
  }
}
</script>
<style scoped lang="less">
.tabBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 98px;
  z-index: 999;
  width: 100%;
  background: white;
  .bar {
    display: flex;
    flex-direction: column;
    justify-items: center;
    color: #333333;
    align-items: center;
    i {
      font-size: 34px;
    }
    span {
      margin-top: 2px;
      font-size: 22px;
    }
  }
  .active {
    color: #fe794e;
  }
}
</style>

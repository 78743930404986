import { request } from './index'

/**
 *
 * @param {*} data  获取用户信息
 * @returns
 */

export function getUserInfo(data) {
  return request({
    method: 'post',
    url: '/user/info',
    data
  })
}
/**
 *
 * @param {*} data  获取用户微信信息
 * @returns
 */

export function getwxUserInfo(data) {
  return request({
    method: 'post',
    url: '/user/WechatInfo',
    data
  })
}

/**
 * 绑定账号
 */
export function addPromotion(data) {
  return request({
    method: 'post',
    url: '/user/addPromotion',
    data
  })
}
/**
 * 修改账号
 */
export function editPromotion(data) {
  return request({
    method: 'post',
    url: '/user/updatePromotion',
    data
  })
}

/**
 * 获取账号分类
 */
export function getPromotionType(data) {
  return request({
    method: 'post',
    url: '/user/promotionType',
    data
  })
}

/**
 * 获取用户推广账号详情
 */
export function getPromotionInfo(data) {
  return request({
    method: 'post',
    url: '/user/promotionInfo',
    data
  })
}

/**
 * 获取联系人接口
 */
export function getContactInformation(data) {
  return request({
    method: 'post',
    url: 'task/ContactInformation',
    data
  })
}
